<template>
  <div>
    <header id="header" class="fixed-top border-bottom bg-main shadow-sm">
      <div class="container-fluid shadow-sm">
        <nav
          class="
            navbar navbar-expand-md navbar-dark
            px-0
            pr-sm-3
            navbar-offcanvas
          "
        >
          <a
            @click="back"
            class="btn p-0 mr-md-4 text-light h5 mb-0"
          >
            <i class="fas fa-arrow-left"></i>
          </a>
          <span
            class="
              navbar-brand
              bg-transparent
              rounded
              p-0
              text-center
              mr-0 mr-md-5
            "
          >
            <span class="h6 font-weight-bold text-truncate">Jenis Produk</span>
          </span>
          <Menu>
            <ul class="navbar-nav w-100 pl-3 pl-md-0">
              <li class="nav-item mt-1 mb-n1">
                <router-link
                  to="/jual/produk"
                  class="nav-link"
                  title="Daftar Produk"
                  >Daftar Produk</router-link
                >
              </li>
              <li class="nav-item mt-1 mb-n1">
                <router-link
                  to="/jual/produk-group"
                  class="nav-link"
                  title="Grup Produk"
                  >Produk Grup</router-link>
              </li>
              <li class="nav-item mt-1 mb-n1">
                <router-link
                  to="/jual/produk-jenis"
                  class="nav-link"
                  title="Jenis Produk"
                  >Jenis Produk</router-link>
              </li>
              <li class="nav-item mt-1 mb-n1">
                <router-link to="/jual/supplier" class="nav-link" title="Supplier"
                  >Supplier</router-link>
              </li>
            </ul>
          </Menu>
        </nav>
      </div>
      <!-- .container -->
    </header>

    <div class="container-fluid mt-header">
      <div class="row" id="body-sidemenu">
        <!-- MAIN -->
        <div id="main-content" class="col with-fixed-sidebar">

          <div class="row horizontal-menu-100 mb-3">
            <div class="col-12 px-0">
              <div class="list-group list-group-horizontal text-center">
                <router-link to="/jual/produk" class="list-group-item flex-fill">PRODUK</router-link>
                <router-link to="/jual/produk-group" class="list-group-item flex-fill">PRODUK GRUP</router-link>
                <a href="javascript:void(0);" class="list-group-item flex-fill on">JENIS</a>
              </div>
          </div> <!-- .col -->
        </div> <!-- .row -->
          <div class="row mt-3 mb-2">
            <div class="col-12">
              <select
                class="form-control form-control-sm"
                id="selFilter"
                required
              >
                <option value="aktif" @click="selectStatus(true)">Aktif</option>
                <option value="inaktif" @click="selectStatus(false)">Inaktif</option>
                <option value="" @click="selectStatus('all')">Semua...</option>
              </select>
            </div>
            <!-- .col -->
          </div>
          <!-- .row -->

          <form @submit.prevent="searchData">
            <div class="input-group input-group-sm my-3">
                <div class="input-group mb-3">
                <input type="text" class="form-control" placeholder="Cari..."
                v-model="state.search" />
                <span class="input-group-append">
                    <button type="submit" class="btn btn-outline-main"><i class="fa fa-search"></i
                ></button>
                </span>
                </div>
            </div>
          </form>

          <div id="resultList" class="row mt-3 mb-5" v-if="state.exist">
            <div class="col-12 col-md-6 col-lg-4 col-xl-3" v-for="dt in state.data" :key="dt.id">
              <div class="card mb-2">
                <div class="card-body p-0">
                  <div class="float-left w-25 border-right">
                    <div
                      class="cropped"
                      :style="'background-image: url('+isPhoto(dt.photo)+');'"
                    ></div>
                  </div>
                  <div class="float-right w-75 pt-2 pl-3">
                    <router-link
                      :to="'/jual/produk-jenis/info/'+dt.id"
                      class="
                        card-title
                        text-main
                        font-weight-bold
                        d-block
                        mb-1
                        mt-1
                        card-link
                        stretched-link
                        text-truncate
                      "
                      >{{ dt.name }}</router-link>

                    <p class="card-text mb-1 text-truncate small">{{ dt.produk_count }} produk</p>
                    <p class="card-text mb-0 text-truncate">
                      <span class="badge mr-2" :class="dt.status === 1? 'badge-secondary':'badge-danger'">{{ dt.status === 1? 'Aktif':'InAktif'}}</span>
                      <!-- <small class="text-muted">Terjual: 145 pcs</small> -->
                    </p>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </div>
            <!-- .col -->
          </div>
          <!-- .row -->
        <div class="row mt-3 mb-5" v-else>
            <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                <h5 class="text-center mt-4">Data Kosong!</h5>
            </div>
        </div>
          <router-link
            to="/jual/produk-jenis/tambah"
            id="btn-float"
            class="
                bg-warning
                position-fixed
                rounded-circle
                shadow
                text-dark text-center
            "
            >
            <div class="d-flex justify-content-center mt-3">
                <i class="fas fa-plus" style="font-size: 25px;"></i>
            </div>
        </router-link>
        </div>
        <!-- main-content -->
      </div>
      <!-- .row -->
    </div>
    <!-- .container -->
  </div>
</template>
<script>
import Menu from "../../../../components/menu/Menu.vue";
import { reactive, onMounted } from 'vue';
import axios from 'axios';
import imageDefault from '../../../../assets/img/img.png';

export default {
    components: {
        Menu,
    },
    setup() {
    const state = reactive({
      data: {},
      exist: false,
      search: '',
      url: process.env.VUE_APP_API_URL,
    })
    const loadData = async() => {
      await axios.get('/api/seller/produk-category').then((res) => {
        if(res.data.success){
          if(res.data.data.length > 0){
            state.exist = true;
            state.data = res.data.data;
          }else{
            state.exist = false;
          }
        }
      })
    }
    onMounted(() => {
      loadData();
    })
    const isPhoto = (dt) => {
        if(dt){
            return state.url + dt;
        }else {
            return imageDefault;
        }
    }
    const searchData = () => {
      if(state.search){
        axios.get('/api/seller/produk-category/search?search='+state.search).then((res) => {
          if(res.data.success){
            if(res.data.data.length > 0){
              state.data = res.data.data;
              state.exist = true;
            }else{
              state.data = {};
              state.exist = false;
            }
          }
        })
      }
    }
    const selectStatus = (dt) => {
      if(dt === true){
        loadData();
      }else{
        axios.get('/api/seller/produk-category/search?status='+dt).then((res) => {
          if(res.data.success){
            if(res.data.data.length > 0){
              state.data = res.data.data;
              state.exist = true;
            }else{
              state.data = {};
              state.exist = false;
            }
          }
        })
      }
    }
    const back = () => {
      window.history.back();
    }
    return {
      state,
      searchData,
      selectStatus,
      isPhoto,
      back
    }
  },
}
</script>